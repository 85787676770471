import React, { Component } from 'react'

export class Contact extends Component {
  render() {
    return (
      <div>
      <h3>Coming soon...</h3>
      </div>
    )
  }
}

export default Contact
